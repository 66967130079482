import styled from 'styled-components';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from './Button';
import { parseDate } from '../utils/parseDate';
import DateInput from './DateInput';
import {
  BaseEventHandler,
  CustomerBM,
  ServicelogV2BM,
  UserFM,
} from '@bm-js/h2o-shared';
import DataList from './DataList';
import OfferFormTable from './OfferFormTable';

const StyledServicelogForm = styled.form`
  .label {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 0.3rem;
  }
  .pointer {
    cursor: pointer;
  }
  .selected-technicians {
    margin-bottom: 1rem;

    .selected-technician {
      margin-bottom: 0.3rem;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      span {
        margin-right: 1rem;
      }
    }
  }
  .dual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .trip {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .flex {
    display: flex;
  }
  .jce {
    justify-content: flex-end;
  }
  .jcfe {
    justify-content: flex-end;
  }
  .asc {
    align-self: center;
  }
  .c {
    text-align: center;
  }
  .m0 {
    margin: 0;
  }
  .mright2 {
    margin-right: 2rem;
  }
  .mbottom05 {
    margin-bottom: 0.5rem;
  }
  .mbottom1 {
    margin-bottom: 1rem;
  }

  .bold {
    font-weight: 700;
  }
`;

type Props = {
  data: ServicelogV2BM | null;
  customer: CustomerBM | null;
  users: UserFM[];
  handleSubmit: (
    formData: any,
    customer: CustomerBM | null
  ) => Promise<void> | void;
};

const ServicelogForm = ({ data, customer, users, handleSubmit }: Props) => {
  const initialFormData = {
    offerDate: '',
    offerNumber: '',
    status: 'Utkast',
    customerReferences: [],
    sellerReferences: [],
    validUntilDate: '',
    latePaymentInterest: 0.12,
    deliveryTerms: '',
    deliveryMethod: '',
    table: [],
    other: [],
  };

  const [formData, setFormData] = useState<ServicelogV2BM>(
    initialFormData as unknown as ServicelogV2BM
  );

  const handleChange: BaseEventHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) setFormData(data);
    //eslint-disable-next-line
  }, [data]);

  return (
    <StyledServicelogForm
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(formData, customer);
      }}
    >
      <div className="trip">
        <div>
          <p className="label">Offertdatum</p>
          <DateInput
            required
            name="offerDate"
            value={formData.offerDate ? parseDate(formData.offerDate) : ''}
            onChange={(e) => {
              const updated = { ...formData };
              updated.offerDate = e.target.value;
              const validTime = 1000 * 60 * 60 * 24 * 90; // 30 days
              const validUntil = parseDate(
                new Date(new Date(e.target.value).getTime() + validTime)
              );
              updated.validUntilDate = validUntil as string;
              setFormData(updated);
            }}
          />
        </div>
        <div>
          <p className="label">Giltig tom</p>
          <DateInput
            required
            name="validUntilDate"
            value={formData.offerDate ? parseDate(formData.validUntilDate) : ''}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="label">Offertnummer</p>
          <input
            required
            type="text"
            name="offerNumber"
            value={formData.offerNumber}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="dual">
        <div>
          <p className="label">Kundens referens</p>
          <DataList
            items={[
              ...(customer?.contactPersonsV2 || []),
              {
                name: customer?.invoiceInformation.reference,
                _id: 'reference',
              },
            ]}
            emitAllChanges
            itemKey="_id"
            identifier="servicelog-customer-reference"
            itemNameKey="name"
            name="reference"
            value={formData.reference}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="label">Vår referens</p>
          {/* eslint-disable-next-line */}
          <select
            required
            name="sellerReferences"
            value={formData.sellerReferences[0]?._id || ''}
            onChange={(e) => {
              const person = users.find((p) => p._id === e.target.value);
              setFormData({ ...formData, [e.target.name]: [person] });
            }}
          >
            <option value="" disabled>
              Välj person
            </option>
            {users.map((person) => (
              <option key={person._id} value={person._id}>
                {person.firstName} {person.lastName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="dual">
        <div>
          <p className="label">Leveransvillkor</p>
          {/* eslint-disable-next-line */}
          <select
            name="deliveryTerms"
            value={formData.deliveryTerms}
            onChange={handleChange}
          >
            <option value="">-</option>
            <option>Fritt vårt lager</option>
          </select>
        </div>
        <div>
          <p className="label">Leveranssätt</p>
          {/* eslint-disable-next-line */}
          <select
            name="deliveryMethod"
            value={formData.deliveryMethod}
            onChange={handleChange}
          >
            <option value="">-</option>
            <option>Transportör</option>
            <option>Kranbil bokad av H2O Teknik</option>
            <option>Tas med av H2O</option>
          </select>
        </div>
      </div>
      <div className="dual">
        <div>
          <p className="label">Märkning</p>
          <select
            value={formData.marking}
            onChange={handleChange}
            name="marking"
          >
            <option value="">-</option>
            {customer?.invoiceInformation.markings.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p className="label">Anbudsnummer</p>
          <select
            value={formData.anbudNumber}
            onChange={handleChange}
            name="anbudNumber"
          >
            <option value="">-</option>
            {customer?.invoiceInformation.anbudNumbers.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="dual">
        <div>
          <p className="label">Faktureringsmetod</p>
          <input disabled value={customer?.invoiceInformation.invoiceMethod} />
        </div>
        {customer?.invoiceInformation.invoiceMethod === 'E-post' && (
          <div>
            <p className="label">E-post fakturering</p>
            <input
              readOnly
              disabled
              value={customer?.invoiceInformation.email}
            />
          </div>
        )}
        {customer?.invoiceInformation.invoiceMethod === 'E-faktura' && (
          <div>
            <p className="label">Uppgifter E-fakturering</p>
            <textarea
              disabled
              readOnly
              value={customer.invoiceInformation?.eInvoiceInfo.join('\n')}
            />
          </div>
        )}
      </div>
      <div className="dual">
        <div>
          <p className="label">Utförande startat</p>
          <DateInput
            name="workStartDate"
            value={formData.workStartDate}
            onChange={handleChange}
          />
        </div>
        <div>
          <p className="label">Utförande avslutat</p>
          <DateInput
            name="workEndDate"
            value={formData.workEndDate}
            onChange={handleChange}
          />
        </div>
      </div>
      <p className="label">Fakturaadress</p>
      <textarea
        value={formData.invoiceAddress?.join('\n')}
        onChange={(e) => {
          setFormData({
            ...formData,
            invoiceAddress: e.target.value.split('\n'),
          });
        }}
      />
      <p className="label">Övrigt</p>
      <textarea
        value={formData.other?.join('\n')}
        onChange={(e) => {
          setFormData({
            ...formData,
            other: e.target.value.split('\n'),
          });
        }}
      />
      <hr />

      <p className="bold">Rader</p>
      <OfferFormTable formData={formData} setFormData={setFormData} />
      <hr />

      <p className="label">Status</p>
      {/* eslint-disable-next-line */}
      <select
        onChange={(e) => {
          setFormData({
            ...formData,
            status: e.target.value,
          });
        }}
        value={formData.status}
      >
        <option>Öppen</option>
        <option>Klar för granskning</option>
        <option>Klar för fakturering</option>
        <option>Fakturerad</option>
      </select>
      <Button type="submit" small>
        Spara
      </Button>
    </StyledServicelogForm>
  );
};

export default ServicelogForm;
