class EnvService {
  public GATSBY_BACKEND_API_URL = process.env.GATSBY_BACKEND_API_URL!;

  public USE_LOCAL_SERVER = process.env.USE_LOCAL_SERVER;
  public GATSBY_IS_DEVELOPMENT = process.env.GATSBY_IS_DEVELOPMENT;
  public GATSBY_IS_MAINTENANCE = process.env.GATSBY_IS_MAINTENANCE;
  constructor() {
    const keys = ['GATSBY_BACKEND_API_URL'] as const;

    keys.forEach((key) => {
      if (!this[key]) throw new Error(`${key} is not set`);
    });
  }
}

export const envService = new EnvService();
